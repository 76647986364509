import React, { useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import AuthApi from 'apis/auth';
import UserApi from 'apis/user';
import { KEY_STORAGE, USER_ROLE } from 'constants/index';
import { parseCookies, setCookie, destroyCookie } from 'nookies';
import { useRouter } from 'next/router';
import { redirectToEncodedOriginUrl } from 'global/utils/router.utils';
import toast from 'react-hot-toast';
import { gtagSetUserId } from 'utils/gtag.utils';

const AuthContext = React.createContext();

const maxAgeCookies = 30 * 24 * 60 * 60;

const DATA_USER_DEFAULT = {
  data: {},
  auth: {
    token: '',
    isLogedIn: false,
    role: '',
  },
};

const makeCookieExpired = () => {
  setCookie(null, 'token', '', {
    maxAge: -1,
    path: '/',
  });
  setCookie(null, 'role', '', {
    maxAge: -1,
    path: '/',
  });

  setCookie(null, 'isLogedIn', '', {
    maxAge: -1,
    path: '/',
  });
};

function AuthProvider(props) {
  const cookies = parseCookies();
  const router = useRouter();

  const { isLoading, data } = useQuery([KEY_STORAGE.DETAIL_USER, cookies?.token], UserApi.getUser, {
    onError: (error) => {
      console.error(error);
    },
    enabled: !!cookies.token,
    retry: false,
  });

  const dataUser = useMemo(() => {
    if (data) {
      return {
        data: data.data,
        auth: {
          token: cookies.token,
          role: cookies.role,
          isLogedIn: cookies.isLogedIn,
        },
      };
    }

    return DATA_USER_DEFAULT;
  }, [data, cookies]);

  const setCookiesAuth = (token, role) => {
    setCookie(null, 'token', token, {
      maxAge: maxAgeCookies,
      path: '/',
      secure: process.env.NODE_ENV === 'production',
      sameSite: 'strict',
    });
    setCookie(null, 'role', role, {
      maxAge: maxAgeCookies,
      path: '/',
      secure: process.env.NODE_ENV === 'production',
      sameSite: 'strict',
    });

    setCookie(null, 'isLogedIn', true, {
      maxAge: maxAgeCookies,
      path: '/',
      secure: process.env.NODE_ENV === 'production',
      sameSite: 'strict',
    });
  };

  const removeCookiesAuth = () => {
    destroyCookie(null, 'token');
    destroyCookie(null, 'role');
    destroyCookie(null, 'isLogedIn');
    makeCookieExpired();
  };

  const fetchLogin = useMutation(AuthApi.login, {
    onSuccess: (responseData) => {
      if (
        responseData?.data?.role === USER_ROLE?.BUYER_ADMIN ||
        responseData?.data?.role === USER_ROLE?.BUYER_MANAGER ||
        responseData?.data?.role === USER_ROLE?.BUYER_STAFF ||
        responseData?.data?.role === USER_ROLE?.CUSTOMER
      ) {
        setCookiesAuth(responseData.data.bearerToken, responseData.data.role);
        redirectToEncodedOriginUrl(router, null, true);
      } else {
        toast.error('Login Gagal', { position: 'top-center' });
      }
    },
  });

  const fetchLogout = useMutation(AuthApi.logout, {
    onSuccess: () => {
      removeCookiesAuth();
      window.location.href = '/';
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const fetchForgotPassword = useMutation(AuthApi.logout, {
    onSuccess: () => {
      removeCookiesAuth();
      window.location.href = '/forgot-password';
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const login = async ({ username, password }) => {
    try {
      const loginResponse = await fetchLogin.mutateAsync({ username, password });
      return loginResponse;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const logout = () => {
    removeCookiesAuth();
    window.location.href = '/';
    fetchLogout.mutate(dataUser?.data?.email);
  };

  const forgotPassword = () => {
    fetchForgotPassword.mutate(dataUser?.data?.email);
  };

  useEffect(() => {
    const {
      data: { id = null },
    } = dataUser || {};
    if (id) {
      gtagSetUserId(id);
    }
  }, [dataUser]);

  return (
    <AuthContext.Provider
      value={{ login, logout, forgotPassword, isLoading, user: dataUser }}
      {...props}
    />
  );
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
